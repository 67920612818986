.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    background-image: url('../../assets/NE3_logo.svg'); /* Agrega el logo como fondo */
    background-size: 50%; /* Ajusta el tamaño del logo (puede ser en porcentaje o en píxeles) */
    background-position: center; /* Centra el logo en la pantalla */
    background-repeat: no-repeat; /* Evita que el logo se repita */
    opacity: 1; /* Controla la transparencia */
}

.login-form {
    width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-button {
    margin-top: 20px;
    background-color: #1976d2;
    color: white;
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.login-error {
    color: red;
    margin-top: 10px;
}
