/* marcadoresInteres.css */

.container2 {
  width: 100%;
  padding: 20px;
}

.file-controls2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Espacio entre los controles y el mapa */
}

/* Estilo personalizado para el botón de guardar */
#save-button2 {
  min-width: 150px;
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Efecto hover para el botón de guardar */
#save-button2:hover {
  background-color: #D4D94E; /* Color secundario */
  color: white; /* Texto cambia al color primario */
}

/* Estilo para el botón de seleccionar ícono */
#marcadores-icon-button2 {
  min-width: 150px; /* Establece un ancho mínimo */
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none; /* Evita que el texto se convierta en mayúsculas */
  font-size: 14px; /* Ajusta el tamaño del texto */
  white-space: nowrap; /* Asegura que el texto no se divida en varias líneas */
  overflow: hidden; /* Oculta cualquier texto que se desborde */
  text-overflow: ellipsis; /* Muestra "..." si el texto se desborda */
  transition: background-color 0.3s ease; /* Suaviza la transición del efecto hover */
}

/* Efecto hover para el botón de seleccionar ícono */
#marcadores-icon-button2:hover {
  background-color: #D4D94E; /* Color secundario en hover */
  color: white;
}

/* Estilo personalizado para el campo de texto */
#marcadores-custom-input2 { 
  flex: 1; /* Hace que el campo de texto sea más ancho que los botones */
  border-radius: 4px;
}

#marcadores-map2 { /* ID único para el mapa */
  height: 400px;
  width: 100%;
}

.custom-input2 {
  flex: 1; /* Hace que el campo de texto sea más ancho que los botones */
  border-radius: 4px;
}

.map2 {
  height: 400px;
  width: 100%;
}


.map3 {
  
  bottom: 0;
  left: 0;
  right: 0;
  height: 85%; /* El mapa ocupa solo una parte de la altura del contenedor */
  width: 100%; /* Ocupa el ancho completo del contenedor del mapa */
}

.file-upload-message2 {
  color: #407352; /* Color verde */
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Estilo para los botones de la fila */
.file-controls2 {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  gap: 10px;  /* Espacio entre los botones */
}

#marcadores-custom-button2 { /* ID único para el botón */
  flex: 1; /* Hace que el botón ocupe un espacio proporcional al de otros botones en el contenedor */
  min-width: 150px; /* Establece un ancho mínimo para el botón */
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none; /* Evita que el texto se convierta en mayúsculas */
  white-space: nowrap; /* Asegura que el texto no se divida en varias líneas */
  overflow: hidden; /* Oculta cualquier texto que se desborde */
  text-overflow: ellipsis; /* Muestra "..." si el texto se desborda */
  transition: background-color 0.3s ease; /* Suaviza la transición del efecto hover */
}

#marcadores-custom-button2:hover { /* Efecto hover */
  background-color: #D4D94E; /* Color secundario en hover */
  color: white; /* Cambia el color del texto en hover si lo deseas */
}

#marcadores-icon-button2 {
  min-width: 150px; /* Establece un ancho mínimo */
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none; /* Evita que el texto se convierta en mayúsculas */
  font-size: 14px; /* Ajusta el tamaño del texto */
  white-space: nowrap; /* Asegura que el texto no se divida en varias líneas */
  overflow: hidden; /* Oculta cualquier texto que se desborde */
  text-overflow: ellipsis; /* Muestra "..." si el texto se desborda */
  transition: background-color 0.3s ease; /* Suaviza la transición del efecto hover */
}

#marcadores-icon-button2:hover {
  background-color: #D4D94E; /* Color secundario en hover */
  color: white;
}
