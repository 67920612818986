/* Estilo personalizado para el botón de color de línea usando id */
#color-picker-button {
    background-color: #03A63C; /* Color primario, permanece constante */
    color: white; /* Texto en blanco */
    border-radius: 4px;
    padding: 10px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Efecto hover para el botón de color de línea usando id */
  #color-picker-button:hover {
    background-color: #D4D94E; /* Color secundario al hacer hover */
    color: white; /* Texto en blanco */
  }
  