/* Contenedor del logo */
.logo-container {
  display: flex;
  flex: 1; /* El contenedor ocupa todo el espacio disponible */
  width: 100%; /* El contenedor cubre todo el ancho del header */
  justify-content: flex-start; /* Elimina la alineación centrada */
}

/* Ajustar el tamaño del banner */
.header-logo {
  width: 100%; /* El banner ocupa todo el ancho del contenedor */
  height: auto; /* Mantiene la proporción del banner */
  max-height: 64px; /* Limita la altura máxima del banner */
}

/* Ajustar el header para que el banner se ajuste correctamente */
.header {
  display: flex;
  align-items: center; /* Centra verticalmente los elementos */
  padding: 0; /* Elimina el padding lateral para que el banner ocupe todo el ancho */
  background-color: #f3f3f4; /* Fondo del encabezado */
  min-height: 64px; /* Altura mínima del encabezado */
  color: white; /* Color del texto */
  z-index: 10; /* Asegura que el encabezado esté encima de otros elementos */
  position: relative; /* Posicionamiento relativo para manejar el z-index */
}

/* Contener el espacio para el logo y los íconos */
.header-icons {
  display: flex;
  align-items: center; /* Centra verticalmente los íconos */
  gap: 10px; /* Espacio entre los íconos */
  position: absolute; /* Posiciona los íconos de manera absoluta */
  right: 20px; /* Ubica los íconos en la esquina derecha del header */
}

/* Estilos del diálogo de logout */
.logout-dialog {
  .MuiDialog-paper {
      border-radius: 12px; /* Bordes redondeados */
      background-color: #f8f9fa; /* Color de fondo claro */
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2); /* Sombra suave */

  }
}

/* Estilos de los botones de confirmación y cancelación */
#cancel-button {
  background-color: red; /* Fondo rojo */
  color: white; /* Texto blanco */
  &:hover {
      background-color: #d9534f; /* Hover más oscuro */
  }
}

#confirm-button {
  background-color: blue; /* Fondo azul */
  color: white; /* Texto blanco */
  &:hover {
      background-color: #5bc0de; /* Hover más claro */
  }
}

/* Estilos del botón de logout */
#logout-button {
  background-color: #ffffff; /* Color de fondo rojo claro */
  border-radius: 8px; /* Bordes redondeados */
  padding: 8px; /* Espaciado interno */
  transition: background-color 0.3s; /* Transición suave para el hover */
}

#logout-button:hover {
  background-color: #ff1a1a; /* Color de fondo más oscuro al pasar el mouse */
}

#logout-button:focus {
  outline: none; /* Quitar el contorno del enfoque */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Sombra al enfocar */
}
