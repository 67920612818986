#color-picker-button {
    background-color: #03A63C; /* Color primario */
    color: white;
    border-radius: 4px;
    padding: 10px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  #color-picker-button:hover {
    background-color: #FFEC00; /* Color secundario al hacer hover */
    color: white;
  }
  