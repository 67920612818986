/* Contenedor principal que incluye todos los elementos del componente */
#recorrido-buses-container2 {
  width: 100%;
  padding: 20px;
}

/* Controles para subir archivo y campos de entrada */
.file-controls2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Espacio entre los controles y el mapa */
}

/* Estilo personalizado para el botón de cargar Excel */
#excel-upload-button2 {

  min-width: 150px;
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Efecto hover para el botón de cargar Excel */
#excel-upload-button2:hover {
  background-color: #028A32;
}

/* Estilo para el campo de texto de la línea */
.custom-input2 {
  flex: 1;
  min-width: 150px;
  margin-left: 10px;
}

/* Botón de guardar archivo */
#save-button2 {
  background-color: #1976d2; /* Color de fondo */
  color: white; /* Color del texto */
  border-radius: 4px;
  text-transform: none;
  margin-left: 10px; /* Espaciado entre el campo de texto y el botón */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Efecto hover para el botón de guardar */
#save-button2:hover {
  background-color: #115293;
}

/* Icono y texto de éxito al cargar archivo */
.success-icon2 {
  margin-left: 10px;
}

.success-text2 {
  margin-left: 5px;
  font-weight: bold;
}

/* Estilo personalizado para la alerta */
.custom-alert2 {
  font-size: 14px;
}

/* Mapa en la parte inferior de la ventana */
.map2 {
  width: 100%;
  height: 500px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
