.icon-selector-container {
  padding: 20px;
  text-align: center;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  gap: 5px; /* Reduce el espacio entre los íconos */
}

.mapaux {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 85%; /* El mapa ocupa solo una parte de la altura del contenedor */
  width: 100%; /* Ocupa el ancho completo del contenedor del mapa */
}

.icon-item {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.icon-item:hover {
  border-color: #1976d2;
}

.icon-item.selected {
  border-color: #03A63C;
}
  