.consulta-poligonos-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px 20px 40px; /* Aumenta el padding-left a 40px para mover el contenido */
    font-family: Arial, sans-serif;
}


.header h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.search-input {
    width: 90%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.poligonos-table {
    width: 80%;
    border-collapse: collapse;
    margin-top: 10px;
    position: relative; /* O absolute, dependiendo de cómo quieras posicionarlo */
    left: 80px; /* Ajusta este valor para mover la tabla a la derecha */
}

.poligonos-table th,
.poligonos-table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}

.poligonos-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.edit-icon {
    color: blue;
    font-size: 20px;
}

.delete-icon {
    color: red;
    font-size: 20px;
}
