.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f9fa; /* Fondo claro */
}

.header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.marcadores-table {
    width: 100%;
    max-width: 900px;
    border-collapse: collapse;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.marcadores-table th, .marcadores-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.marcadores-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #555;
}

.marcadores-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.marcadores-table tr:hover {
    background-color: #e9ecef;
}

.action-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.action-button svg {
    width: 20px;
    height: 20px;
}

.delete-icon {
    color: red;
}

.export-icon {
    color: green;
}

.edit-icon {
    color: blue;
}
