/*ExcelUpload.css*/
#excel-upload-button { /* ID único para el botón de carga */
    background-color: #03A63C; /* Color de fondo inicial */
    color: white; /* Color del texto */
    border-radius: 4px;
    padding: 10px;
    text-transform: none; /* Evita que el texto se convierta en mayúsculas */
    white-space: nowrap; /* Asegura que el texto no se divida en varias líneas */
    overflow: hidden; /* Oculta cualquier texto que se desborde */
    text-overflow: ellipsis; /* Muestra "..." si el texto se desborda */
}

#excel-upload-button:hover { /* Estilo al pasar el mouse */
    background-color: #D4D94E; /* Color de fondo al hacer hover */
    color: white; /* Color del texto al hacer hover */
}
