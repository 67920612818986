.container {
    width: 100%;
    padding: 20px;
  }
  
  .mapContainer {
    height: 400px; /* Altura del mapa */
    width: 100%;
  }
  
  .loadingMessage {
    text-align: center;
    font-size: 18px;
    color: #333;
  }