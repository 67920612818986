.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.mapContainer {
  width: 100%;
  height: 400px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.topButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.backButton {
  background-color: #03a63c;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
}

.backButton:hover {
  background-color: #028a32;
  color: white;
}

.saveButton {
  background-color: #03a63c !important;
  color: white !important;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.saveButton:hover {
  background-color: #028a32 !important;
}

