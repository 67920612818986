/* Contenedor principal */
.container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
  }
  
  /* Filtros y acordeón */
  .filters-and-accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Espacio entre filtros y el contenedor del acordeón */
  }
  
  /* Controles de filtros */
  .filters-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Espacio entre las columnas de filtros */
    width: 100%;
  }
  
  /* Filtro individual */
  .filter {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Espacio entre el campo de entrada y la lista de opciones */
  }
  
  /* Controles para subir archivo y campos de entrada */
  .file-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Espacio entre los controles y el mapa */
  }
  
  /* Estilo personalizado para los botones */
  .custom-button {
    flex: 1;
    min-width: 150px;
    background-color: #FFEC00; /* Amarillo */
    color: #176A36; /* Texto en verde oscuro para contraste */
    border-radius: 4px;
    padding: 10px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  
  /* Estilo personalizado para el campo de entrada */
  .custom-input {
    flex: 1;
    border-radius: 4px;
    padding: 10px;
    border: 2px solid #03A63C; /* Borde verde brillante */
    color: #176A36; /* Texto en verde oscuro */
  }
  
  /* Contenedor del mapa */
  .map {
    width: 100%;
    height: 500px; /* Ajusta la altura según tus necesidades */
    position: relative;
    z-index: 1; /* Asegúrate de que el mapa tenga un z-index menor */
  }
  
  .filters-container {
    position: relative;
    z-index: 2; /* Asegúrate de que los filtros tengan un z-index mayor */
  }
  
  .custom-autocomplete {
    position: relative;
    z-index: 2; /* Asegúrate de que los inputs tengan un z-index mayor */
  }
  
  .filter {
    position: relative;
    z-index: 2; /* Asegúrate de que los inputs tengan un z-index mayor */
  }
  
  /* Estilos adicionales para mejorar la experiencia del usuario */
  
  /* Alerta personalizada en Snackbar */
  .custom-alert {
    font-size: 1rem;
    font-weight: bold;
    background-color: #03A63C; /* Verde brillante */
    color: white; /* Texto en blanco */
  }
  
  /* Estilos para íconos y textos cuando el archivo Excel está cargado */
  .success-icon {
    color: #FFEC00; /* Amarillo para el ícono */
    margin-right: 8px;
  }
  
  .success-text {
    font-size: 0.9rem;
    color: #176A36; /* Texto en verde oscuro */
  }
  
  /* Estilos de los Accordion */
  .MuiAccordionSummary-root {
    background-color: #eef3ee !important; /* Verde claro */
  }
  
  .MuiAccordionDetails-root {
    background-color: #ffffff; /* Blanco grisáceo más claro */
  }
  
  .MuiAccordionSummary-expandIconWrapper {
    color: #858181 !important; /* Color amarillo para el ícono de expansión */
  }
  
  .MuiAccordionSummary-content .MuiTypography-root {
    color: #FFFFFF !important; /* Texto en blanco */
    font-weight: bold;
    font-size: 1.1rem; /* Tamaño de fuente más pequeño */
    padding: 0px 0; /* Menos espaciado superior e inferior */
    margin: -10px 0;
  }
  
  /* Estilos específicos para las listas dentro de los Accordion */
  .MuiListItem-root {
    background-color: #fffcfc; /* Verde brillante para los elementos de la lista */
    border-bottom: 1px solid #000000; /* Línea divisoria verde oscuro */
    color: rgb(245, 29, 29); /* Texto en blanco */
  }
  
  .MuiCheckbox-root {
    color: #bbbbbb !important; /* Checkbox en amarillo */
  }
  
  /* Estilo del botón guardar mapa */
  #save-map-button {
    position: absolute;
    top: -15px; /* Mover el botón un poco más arriba */
    right: 70px;
    background-color: #4caf50; /* Color de fondo personalizado */
    color: white; /* Color del texto */
    padding: 10px;
    border: none;
    border-radius: 4px;
  }
  
  #save-map-button:hover {
    background-color: #dfe450; /* Color al pasar el cursor */
  }
  
  /* Ajustar el margen de los autocompletados */
  .custom-autocomplete {
    width: 300px; /* Ajuste del ancho */
    margin-top: 20px; /* Agregar margen superior a los autocompletados */
  }
  
  /* Contenedor del acordeón */
  .accordion-container {
    transform: translate(-20px, 23px); /* Ajusta según sea necesario */
    margin-bottom: 20px; /* Espacio adicional debajo */
    width: fit-content; /* Ajuste al ancho del contenido */
    align-self: center; /* Centra dentro del contenedor flexible */
  }
  
  /* Botón del acordeón */
  .accordion-button {
    width: 600px; /* Ajuste del ancho */
    height: 50px;
    background-color: #176A36;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Contenedor del acordeón */
  .accordion-container {
    transform: translate(-20px, 23px); /* Ajusta según sea necesario */
    margin-bottom: 20px; /* Espacio adicional debajo */
    width: 100%; /* Ajusta al 100% del ancho del contenedor padre */
    max-width: 600px; /* Define un ancho máximo para el acordeón */
    align-self: center; /* Centra dentro del contenedor flexible */
  }
  
  /* Botón del acordeón */
  .accordion-button {
    width: 100%; /* Cambia a 100% para ser responsivo */
    height: 50px;
    background-color: #176A36;
    color: white;
    font-size: 1.2rem; /* Usa 'rem' para escalar con el tamaño de fuente del usuario */
    font-weight: bold;
  }
  
  /* Contenedor de los checkboxes */
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center; /* Alinea los checkboxes al centro */
    align-items: flex-start;
    width: 100%; /* Asegúrate de que ocupe el 100% del ancho del acordeón */
  }
  
  /* Estilo personalizado para los checkboxes */
  .custom-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw; /* Usa 'vw' para escalar con el ancho de la ventana */
    height: 40px;
    max-width: 60px; /* Mantiene el tamaño máximo para no exceder */
    border-radius: 20px;
    background-color: #176A36;
    color: white;
    font-size: 1rem; /* Usa 'rem' para escalar con el tamaño de fuente del usuario */
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  
  .custom-checkbox.selected {
    background-color: #FFEC00; /* Color cuando está seleccionado */
    color: white;
  }
  
  .custom-checkbox input[type="checkbox"] {
    display: none; /* Oculta el checkbox original */
  }
  
  @media (max-width: 768px) {
    .accordion-button {
      font-size: 1.2rem; /* Reduce tamaño de fuente en pantallas pequeñas */
    }
  
    .custom-checkbox {
      font-size: 0.9rem; /* Reduce tamaño de fuente en checkboxes */
      height: 35px; /* Ajusta altura */
    }
  }