/* Contenedor principal que incluye todos los elementos del componente */
.container2 {
  width: 100%;
  padding: 20px;
}

/* Controles para subir archivo y campos de entrada */
.file-controls2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Espacio entre los controles y el mapa */
}

/* Estilo personalizado para el botón de cargar Excel */
#excel-upload-button2 {
 
  min-width: 150px;
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Efecto hover para el botón de cargar Excel */
#excel-upload-button2:hover {
  background-color: #D4D94E; /* Color secundario */
  color: white; /* Texto cambia al color primario */
}

/* Estilo personalizado para el botón de guardar */
#save-button2 {
  
  min-width: 150px;
  background-color: #03A63C; /* Color primario */
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Efecto hover para el botón de guardar */
#save-button2:hover {
  background-color: #D4D94E; /* Color secundario */
  color: white; /* Texto cambia al color primario */
}

/* Estilo personalizado para el campo de entrada de texto */
.custom-input2 {
  
  border-radius: 4px;
  padding: 10px;
  /* Agrega padding para mejorar la apariencia */
}

/* Contenedor del mapa */
.map2 {
  width: 100%;
  height: 500px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Opcional: Estilos adicionales para mejorar la experiencia del usuario */

/* Alerta personalizada en Snackbar */
.custom-alert2 {
  font-size: 1rem; /* Ajusta el tamaño de la fuente de las alertas */
  font-weight: bold;
}

/* Estilos para íconos y textos cuando el archivo Excel está cargado */
.success-icon2 {
  color: green;
  margin-right: 8px;
}

.success-text2 {
  font-size: 0.9rem;
  color: green;
}
/* Estilo para el contenedor del input */
.input-container {
  width: 200px;  /* Ajusta el ancho del contenedor */
  display: inline-block;  /* Si necesitas que el contenedor esté en línea con otros elementos */
  margin-right: 10px;  /* Espacio entre el input y otros elementos */
}

/* Estilo personalizado para el campo de entrada de texto */
#zona-input {
  width: 100%;  /* El input ocupará todo el ancho del contenedor */
}
