/* src/Sidebar.css */
.drawer {
    width: 180px; /* Ancho del Drawer */
    background-color: #03A63C; /* Color de fondo del Sidebar */
    color: #A5F257; /* Color del texto y los iconos */
}

.list-item {
    margin-bottom: 8px; /* Espaciado entre los botones */
}

.drawer .list-item:hover {
    background-color: #FFEC00 !important; /* Usa !important si es necesario */
}

.list-item.selected {
    background-color: #A5F257; /* Color de fondo para el ítem seleccionado */
    color: #03A63C; 
}

.button {
    width: 140px; /* Ancho de los botones */
    font-size: 12px; /* Tamaño de la fuente de los botones */
}
