/* css de app.js*/
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Altura completa de la pantalla */
}

.App-header {
  background-color: #282c34;
  min-height: 64px; /* Altura del encabezado */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 10; /* Asegúrate de que el encabezado esté encima del mapa y el botón */
}

.content {
  margin-left: 260px; /* Ancho de la barra lateral */
  margin-top: 5px; /* Altura del encabezado */
  flex-grow: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.map-container {
  position: relative;
  height: calc(100% - 20px); /* Altura completa menos la altura del encabezado */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
}

.map {
  
  bottom: 0;
  left: 0;
  right: 0;
  height: 85%; /* El mapa ocupa solo una parte de la altura del contenedor */
  width: 100%; /* Ocupa el ancho completo del contenedor del mapa */
}

.file-controls {
  position: absolute;
  top: 10px; /* Ajusta la distancia desde el borde superior */
  left: 10px; /* Ajusta la distancia desde el borde izquierdo */
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre los elementos */
}

.file-controls button {
  background-color: #1976d2; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 4px; /* Esquinas ligeramente redondeadas */
  padding: 10px 20px; /* Ajusta el tamaño del botón, el valor del padding puede ser modificado */
  cursor: pointer;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.file-controls input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 2; /* Hacer que el campo de texto sea más ancho que los botones */
}

.floating-button {
  display: none; /* Oculta el botón flotante para usar el nuevo diseño */
}
/* App.css */
.custom-save-button {
  background-color: #1976d2; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 4px; /* Esquinas ligeramente redondeadas */
  padding: 10px; /* Tamaño del botón */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px; /* Espacio entre el ícono y el texto */
}

.custom-save-button .MuiCircularProgress-root {
  color: white; /* Color del indicador de carga */
}

