.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(168, 164, 164);
}

.search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.maps-table {
    width: 100%;
    border-collapse: collapse;
}

.maps-table th, .maps-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.maps-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
}

.maps-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.maps-table tr:hover {
    background-color: #ddd;
}

.maps-table th, .maps-table td {
    text-align: left;
    padding: 12px;
}