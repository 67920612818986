/* Contenedor principal */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Encabezado con botones alineados a la derecha */
.headerRow {
    display: flex;
    justify-content: space-between; /* Separar título y botones */
    align-items: center;
    margin-bottom: 20px;
}

/* Contenedor para los botones alineados a la derecha */
.buttonContainer {
    display: flex;
    flex-direction: column; /* Apila los botones uno debajo del otro */
    align-items: flex-end; /* Alinea los botones a la derecha */
    gap: 10px; /* Espaciado entre botones */
}

/* Botón Guardar Cambios */
.saveButton {
    background-color: #03A63C !important;
    color: white;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    text-transform: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.saveButton:hover {
    background-color: #028A31 !important;
    transform: scale(1.05);
}

/* Botón Exportar Excel */
.exportButton {
    background-color: #03A63C !important;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.exportButton:hover {
    background-color: #028A2D !important;
}

/* Inputs directamente en el contenedor */
.inputGroup {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Permite que los inputs se ajusten si el espacio es limitado */
    margin-bottom: 20px;
}

/* Inputs individuales con margen entre ellos */
.inputField {
    border-radius: 4px;
    padding: 0px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100%; /* Los inputs ocuparán el 100% de su contenedor */
    max-width: 300px; /* Limita el tamaño máximo */
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    margin-bottom: 10px; /* Añade un margen entre los inputs */
}

.inputField:focus {
    border-color: #03A63C !important;
    outline: none;
}

/* Grupo de botones a la derecha */
.buttonGroup {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

/* Botones Seleccionar Ícono y Añadir Marcador */
.iconButton,
.addButton {
    background-color: #03A63C !important;
    color: white;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    text-transform: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.iconButton:hover,
.addButton:hover {
    background-color: #028A31 !important;
    transform: scale(1.05);
}

/* Estilo para el mapa */
#edit-map {
    height: 450px;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.map {
    height: 100%;
    width: 100%;
    border: none;
}

/* Títulos */
h2 {
    font-size: 16px;
    color: #333;
    margin: 0;
    font-weight: bold;
}

/* Botón Volver */
.backButton {
    background-color: #03A63C !important;   /* Color de fondo */
    color: white;                 /* Color del texto */
    border: none;                 /* Sin borde */
    padding: 8px 16px;            /* Relleno (espaciado interno) */
    border-radius: 4px;           /* Bordes redondeados */
    font-size: 16px;              /* Tamaño de fuente */
    cursor: pointer;             /* Cambiar el cursor a mano cuando se pasa sobre el botón */
    display: flex;
    align-items: center;          /* Alineación vertical del ícono y texto */
    gap: 8px;                     /* Espacio entre el ícono y el texto */
}

.backButton:hover {
    background-color: #028A2D !important;   /* Color más oscuro cuando se pasa el mouse sobre el botón */
}
